/* eslint-disable prettier/prettier */
import { orderBy, where } from 'firebase/firestore';
import { Product } from "../stripe/product.js";
import { createUniqueId } from '../../../../pure/libs/Common.js';
import { getPathToUploadedFiles } from '../../../../pure/libs/UploadPathHelper.js';
import { Upload, UploadedFile } from '../../../../pure/types/JaguarTypes.js';
import Collections from '../../../../pure/libs/Collections.js';
import { Organization, Ad, ExternalSession, User } from '../../../../pure/types/types.js';
import { VitecNextOrder } from '../../../../pure/types/VitecNODerivedTypes.js';
import { getObjectQuery, getObjectsQuery, getAllUsersForUserQuery, getAllObjectsWithUserIdQuery, getQuery } from '../libs/FirebaseStorageClientForWebApp.js';
import { useFirestoreQuery } from './useFirestoreQuery.js';



export const useUser = (id: string) => useFirestoreQuery<User>(getObjectQuery(id, Collections.USERS), {fnName: 'useUser', enabled: !!id})
export const useStripeProducts = () => useFirestoreQuery<Product[]>(getObjectsQuery(Collections.PRODUCTS), {fnName: 'useStripeProducts'})


// Single Objects
export const useOrganization = (organizationId = '') =>
  useFirestoreQuery<Organization>(getObjectQuery(organizationId as string, Collections.ORGANIZATIONS), {
    fnName: 'useOrganization',
    enabled: !!organizationId
  })

export const useAd = (id = '') =>
  useFirestoreQuery<Ad>(getObjectQuery(id as string, Collections.ADS), {
    fnName: 'useAd',
    enabled: !!id
  })

export const useExternalSession = (id = '') =>
  useFirestoreQuery<ExternalSession>(getObjectQuery(id as string, Collections.EXTERNAL_SESSIONS), {
    fnName: 'useExternalSession',
    enabled: !!id
  })

export const useUsersForOrganization = (orgId = '') =>
    useFirestoreQuery<User[]>(getAllUsersForUserQuery(orgId as string), {fnName: 'useUsersForOrganization', enabled: !!orgId})

export const useAds = (uid = '') =>
    useFirestoreQuery<Ad[]>(getAllObjectsWithUserIdQuery(uid, Collections.ADS, [orderBy('createdAt', 'desc')]), {fnName: 'useAds', enabled: !!uid})

export const useUploads = (uid = '') =>
    useFirestoreQuery<Upload[]>(getQuery(Collections.UPLOADS, [where('metaData.userId', '==', uid)]), {fnName: 'useUploads', enabled: !!uid})

export const _useUploadedFiles = (id: string) => useFirestoreQuery<UploadedFile[]>(getObjectsQuery(getPathToUploadedFiles(id)), {fnName: 'useUploadedFiles', enabled: !!id})

export const useUploadedFile = (fileId: string, upload?: Upload) => useFirestoreQuery<UploadedFile>(getObjectQuery(fileId, getPathToUploadedFiles(upload?.id || createUniqueId()) as Collections), {fnName: 'useUploadedFile', enabled: !!fileId && !!upload?.id})

export const useUpload = (id = '') =>
  useFirestoreQuery<Upload>(getObjectQuery(id as string, Collections.UPLOADS), {
    fnName: 'useUpload',
    enabled: !!id
  })

export const useVitecNOOrder = (id = '') =>
  useFirestoreQuery<VitecNextOrder>(getObjectQuery(id as string, Collections.VITEC_NO_ORDER), {
    fnName: 'useVitecNoOrder',
    enabled: !!id
  })

  export const useExternalSessions = (fasadUserId?: number) =>
    useFirestoreQuery<ExternalSession[]>(getQuery(Collections.EXTERNAL_SESSIONS, [where('userId', '==', fasadUserId || '')]), {fnName: 'useExternalSessions', enabled: !!fasadUserId})