import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { initializeFirestore } from 'firebase/firestore'
import { getFunctions } from 'firebase/functions'

import { getStorage } from 'firebase/storage'
import { EUROPE_WEST_3, REGION } from '../../../../pure/libs/Consts'

// For Firebase JS SDK v7.20.0 and later, measurementId is optional

// Configure Firebase.
export const app = initializeApp({
  apiKey: 'AIzaSyBwPws1J9AK1r21b9SKt3oqGErcPU86n1A',
  authDomain: 'app.gazelle.work',
  projectId: 'gazelle-4d5d9',
  storageBucket: 'gazelle-4d5d9.appspot.com',
  messagingSenderId: '308833176620',
  appId: '1:308833176620:web:832a2b5dbb1c2353b81589',
  measurementId: 'G-NZBJMZ6TZN'
})

export const db = initializeFirestore(app, { ignoreUndefinedProperties: true })

export const auth = getAuth(app)

export const functions = getFunctions(app, REGION)

export const storage = getStorage(app)
