/* eslint-disable prettier/prettier */
// @flow
import { StyledEngineProvider, Theme, ThemeProvider } from '@mui/material'
import StylesProvider from '@mui/styles/StylesProvider'
import { SnackbarProvider } from 'notistack'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { Context, useAppStateContext } from '../hooks/useAppState'
import '../libs/Fonts'
import theme from '../libs/theme'
import { SNACK_BAR_PROPS } from '../../../../pure/libs/Consts'
import RoutePath from '../../../../pure/libs/RoutePath'
import ErrorBoundaryProvider from './ErrorBoundaryProvider'
import RootPage from './RootPage'

import { Toaster } from 'react-hot-toast'
import { OnMountApp } from '../hooks/useOnMountApp'
import { InfomapPage } from '../pages/InfomapPage'
import { LoginWithCustomerPage } from '../pages/LoginWithCustomerPage'
import AdCreatorPage from './AdCreatorPage'
import ExternalSessionPage from './ExternalSessionPage'
import { Helmet } from './Helmet'
import { PrivacyPolicyIframe, TermsAndConditionsIframe } from './Iframes'
import MyOrganizationPage from './MyOrganizationPage'
import PricingPage from './PricingPage'
import PricingWebIframePage from './PricingWebIframePage'
import ProductsPage from './ProductsPage'
import SandboxPage from './SandboxPage'
import SettingsPage from './SettingsPage'


declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}


const App = () => {
  const context = useAppStateContext()
  
  return (
    <ErrorBoundaryProvider>
      <StylesProvider injectFirst>
        <SnackbarProvider {...SNACK_BAR_PROPS}>
            <Context.Provider value={context}>
              <BrowserRouter>
                <StyledEngineProvider injectFirst>
                  <ThemeProvider theme={theme}>
                    <Helmet titleTemplate="%s - Gazelle"/>
                      <OnMountApp />
                      <Toaster position="top-center" reverseOrder={false} toastOptions={{ duration: 2000 }} />                    
                      <Routes>
                        <Route path={RoutePath.ROOT} Component={RootPage}>
                          <Route path={RoutePath.SETTINGS} Component={SettingsPage} />
                          <Route path={RoutePath.ROOT} Component={AdCreatorPage} />
                          <Route path={RoutePath.CREATE_AD} Component={AdCreatorPage} /> 
                        </Route>
                        
                        <Route path={RoutePath.INFO_MAP} Component={InfomapPage} />
                        <Route path={RoutePath.LINKED_IN} Component={RootPage} />
                        <Route path={RoutePath.SANDBOX} Component={SandboxPage} />
                        <Route path={RoutePath.MY_ORG} Component={MyOrganizationPage} />
                        <Route path={RoutePath.PRICING} Component={() => <PricingPage />} />
                        <Route path={RoutePath.PRICING_WEB_IFRAME} Component={() => <PricingWebIframePage />} />
                        <Route path={RoutePath.PRODUCTS} Component={ProductsPage} />
                        <Route path={RoutePath.TERMS_AND_CPNDITIONS} Component={TermsAndConditionsIframe} />
                        <Route path={RoutePath.PRIVACY_POLICY} Component={PrivacyPolicyIframe} />
                        <Route path={RoutePath.EXTERNAL_SESSION} Component={ExternalSessionPage} />
                        <Route path={RoutePath.LOGIN_WITH_CUSTOMER} Component={LoginWithCustomerPage} />
                      </Routes>
                  </ThemeProvider>
                </StyledEngineProvider>
              </BrowserRouter>
            </Context.Provider>
        </SnackbarProvider>
      </StylesProvider>
    </ErrorBoundaryProvider>
  );
}

export default App



